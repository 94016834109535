import {supabase} from "../../../configs/supabase";
import {ErrorCode} from "../../services/const/ErrorCode";
import {RealtimeChannel} from "@supabase/supabase-js";
import Notification from "../../services/notification/Notification";
import t from "../../../configs/i18n";

export class InputValue {
    id: string;
    value: string;
    validated: boolean;

    public constructor(obj: any) {
        this.id = obj.id;
        this.value = obj.value;
        this.validated = obj.validated;
    }

    public static newInputValue(obj: any) : InputValue | undefined {
        if (!obj) {
            return undefined;
        }
        return new InputValue(obj);
    }

    public static async byId(id: string | undefined, showError : boolean = true) : Promise<InputValue | undefined> {
        console.log("__INPUT_BY_ID__")
        if (!id) {
            showError && Notification.error(t.error.input, ErrorCode.EMPTY_VALUE);
            return undefined;
        }

        const response = await supabase
            .from("input")
            .select("value")
            .eq("id", id);

        if (response.error) {
            showError && Notification.error(t.error.input, response.error);
            return undefined;
        }

        return InputValue.newInputValue(response.data?.at(0));
    }

    public static async updateOrCreate(id: string | undefined, value: string | undefined, groupName: string | undefined = undefined, sender? : string) : Promise<void> {
        if (!id) {
            Notification.error(t.error.input, ErrorCode.EMPTY_VALUE);
            return;
        }

        const response = await supabase
            .from("input")
            .upsert({
                id: id,
                value: value,
                group_name: groupName,
                sender: sender
            });

        if (response.error) {
            Notification.error(t.error.input, response.error);
        }
    }


    public static listen(id: string | undefined, sender: string | undefined, callback: (value: string | undefined) => void) : RealtimeChannel | undefined{
        if (!id) {
            Notification.error(t.error.input, ErrorCode.EMPTY_VALUE);
            return;
        }

        return supabase
            .channel(id)
            .on("postgres_changes",
                {
                    schema: 'public',
                    table: 'input',
                    filter: `id=eq.${id}`,
                    event: '*',
                }, (payload) => {
                    if (payload.eventType === "DELETE") {
                        if (payload.old.id === id) {
                            callback(undefined);
                        }
                        return;
                    }

                    if (payload.new.sender === sender) {
                        return;
                    }

                    callback(InputValue.newInputValue(payload.new)?.value)
                }).subscribe();
    }
}
